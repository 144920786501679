<template>
  <div class="container">
    <div class="row">
      <div class="col-sm-12 pm-0">
        <div class="w-100 text-right">
          <modal-btn target="#add-partner" btn-class="btn-sm btn-soft-danger"
            >إضافة</modal-btn
          >
        </div>
        <div class="table-responsive">
          <table class="table table-bordered">
            <tr class="bg-bb">
              <th>الشريك</th>
              <th>الصورة</th>
              <th>تعديل</th>
            </tr>
            <tr v-if="emptyPartners">
              <td colspan="3"><strong>لا يوجد شركاء</strong></td>
            </tr>
            <StrategicPartnerItem
              v-else
              v-for="partner in partners"
              :key="partner.id"
              :partner="partner"
              @deleted="deleted"
              @updated="setData"
            />
          </table>
        </div>
      </div>
    </div>
    <teleport to="#app">
      <confirm-model
        title="إضافة شريك"
        target-id="add-partner"
        confirm-text="إضافة"
        @confirmed="clickById('submit-add-partner')"
      >
        <form @submit.prevent="store">
          <div class="form-group">
            <label>إسم الشريك</label>
            <form-input id="name" placeholder="إسم الشريك"></form-input>
          </div>
          <div class="form-group">
            <form-image id="image" ref="image">تحميل الصورة</form-image>
          </div>
          <button hidden id="submit-add-partner" type="submit"></button>
        </form>
      </confirm-model>
    </teleport>
  </div>
</template>

<script>
import StrategicPartnerItem from "@/components/Admin/StrategicPartnerItem";
import ModalBtn from "@/components/UI/ModalBtn";
import ConfirmModel from "@/components/UI/ConfirmModel";
import FormInput from "@/components/Forms/FormInput";
import FormImage from "@/components/Forms/FormImage";
import arrays from "@/common/arrays";

export default {
  components: {
    FormImage,
    FormInput,
    ConfirmModel,
    ModalBtn,
    StrategicPartnerItem,
  },
  computed: {
    partners() {
      return this.$store.getters.partners;
    },
    emptyPartners() {
      return !this.partners || this.partners.length === 0;
    },
  },
  methods: {
    async store(e) {
      if (!e) return;

      const file = this.$refs.image;
      const formData = new FormData(e.target);
      if (!formData.get("name"))
        return http.popupMessage("error", "يرجى كتابة اسم الشريك");

      if (!!file && file.fileIsEmpty("image"))
        return http.popupMessage("error", "يرجى رفع صورة الشعار");

      const response = await http.send("partners", formData);
      if (http.responseAccepted(response)) {
        await this.setData(response.data);
        this.hideModal("#add-partner");
        $_("#name").val("");

        if (!!file) file.clearInputFile("image");

        http.successMessage(messages.saved);
      }
    },
    deleted(id) {
      this.setData(arrays.removeById(this.partners, id));
    },
    async setData(data = "") {
      await this.$store.dispatch("setPartnersAction", data);
    },
  },
  async created() {
    await this.setData();
  },
};
</script>
